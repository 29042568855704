import React, { ReactElement, useEffect, useState } from 'react';
import { Formik, Field, Form, useFormikContext } from 'formik';
import styled from 'styled-components';

import { FieldWrapper, FieldSubtitle, Tag } from '@rentecarlo/component-library';

import { InputField, PasswordInputField } from 'components/atoms';
import { Page } from 'components/templates';
import { buildQuoteUrl } from 'utils/clientUtils';
import { isEmail, isRequiredPassword } from 'utils/formValidation';
import { NewToVeygo } from 'components/organisms';

import QuestionMarkIcon from 'assets/field-subtitle-question-mark.svg';
import { ComponentProps } from './Login.container';

const Spacer = styled.div`
  height: 40px;
`;

export interface FormValues {
  username: string;
  password: string;
}

const LoginForm = ({
  emailValid,
  emailLoading,
  passwordValid,
  passwordLoading,
  invalidateEmail,
  invalidatePassword,
  resetLogin,
  redirect,
}: ComponentProps): ReactElement => {
  const [formActive, setFormActive] = useState(false);
  const {
    values: { username, password },
    handleSubmit,
    handleBlur,
    handleChange,
    setValues,
    errors,
    setErrors,
    isValid,
  } = useFormikContext<FormValues>();
  const isNewDriver = window.location.search.includes('product=newdriver');

  // Should later be replaced by refs
  const handleInputBlur = (id: string) => document.getElementById(id)?.blur();

  useEffect(() => {
    if (errors.username && username.length > 0) {
      handleInputBlur('loginEmail');
    }
    if (errors.password && password.length > 0) {
      handleInputBlur('loginPassword');
    }
  }, [errors.username, errors.password, username, password]);

  useEffect(() => {
    if (emailValid === false || passwordValid === false) {
      handleInputBlur(`login${emailValid ? 'Password' : 'Email'}`);
    }
  }, [emailValid, passwordValid]);

  useEffect(() => {
    if (emailValid === true) {
      setFormActive(false);
    }
  }, [emailValid]);

  return (
    <Page
      title='Sign in'
      buttonText='Continue'
      buttonAction={handleSubmit}
      buttonDisabled={!isValid || emailValid === false || passwordValid === false}
      buttonIsLoading={emailLoading || passwordLoading}
      subtitle={
        <FieldSubtitle
          icon={QuestionMarkIcon}
          text='Usually sign in with Facebook?'
          onClick={(): void => {
            window.location.href =
              'https://www.veygo.com/help-center/usually-sign-in-with-facebook';
          }}
        />
      }
      bottomContent={
        isNewDriver ? (
          ''
        ) : (
          <NewToVeygo
            buttonAction={(): void => {
              window.location.href = buildQuoteUrl();
            }}
          />
        )
      }
    >
      {!emailValid && (
        <FieldWrapper
          title='Email'
          subtitle={
            <FieldSubtitle
              icon={QuestionMarkIcon}
              text='Forgot email?'
              onClick={(): void => redirect('email/forgot')}
            />
          }
        >
          <Field
            id='loginEmail'
            name='username'
            error={
              emailValid === false ? 'We cannot find an account with that email address' : undefined
            }
            placeholder='example@veygo.com'
            value={username}
            validate={formActive && isEmail}
            onBlur={handleBlur}
            onChange={(e: Event): void => {
              handleChange(e);
              if (formActive === false) {
                setFormActive(true);
              }
              if (emailValid === false || errors.username) {
                invalidateEmail();
                setErrors({});
              }
            }}
            component={InputField}
            type='email'
            autoFocus
          />
        </FieldWrapper>
      )}
      {emailValid && (
        <>
          <Tag
            onClose={(): void => {
              setValues({
                username: '',
                password: '',
              });
              setErrors({});
              resetLogin();
            }}
            text={username}
          />
          <Spacer />
          <FieldWrapper
            title='Password'
            subtitle={
              <FieldSubtitle
                icon={QuestionMarkIcon}
                text='Forgot password?'
                onClick={(): void => redirect('password/forgot')}
              />
            }
          >
            <Field
              id='loginPassword'
              name='password'
              error={
                passwordValid === false
                  ? "Hmm, that's not the right password. Give it another try or use the forgot password link."
                  : undefined
              }
              placeholder=''
              value={password}
              validate={formActive && isRequiredPassword}
              onBlur={handleBlur}
              onChange={(e: Event): void => {
                handleChange(e);
                if (formActive === false) {
                  setFormActive(true);
                }
                if (passwordValid === false || errors.password) {
                  setErrors({});
                  invalidatePassword();
                }
              }}
              component={PasswordInputField}
              autoFocus
            />
          </FieldWrapper>
        </>
      )}
    </Page>
  );
};

const Login = (props: ComponentProps): ReactElement => {
  const { submit, email } = props;
  const initialValues: FormValues = {
    username: email,
    password: '',
  };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values: FormValues): void => submit(values)}
      validateOnChange={false}
    >
      <Form>
        <LoginForm {...props} />
      </Form>
    </Formik>
  );
};

export default Login;
