import React, { ReactElement } from 'react';
import { Formik, Field, Form } from 'formik';
import { FieldSubtitle } from '@rentecarlo/component-library';

import { InputField } from 'components/atoms';
import { Page } from 'components/templates';
import { isEmail } from 'utils/formValidation';
import QuestionMarkIcon from 'assets/field-subtitle-question-mark.svg';

import * as styles from './assets/styles';
import { ComponentProps } from './PasswordForgot.container';

export interface FormValues {
  username: string;
}

const PasswordForgot = (props: ComponentProps): ReactElement => {
  const { emailValid, invalidateEmail, username, submit, redirect, recoveryLoading } = props;
  const initialValues: FormValues = {
    username,
  };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values: FormValues): void => submit(values)}
      validateOnChange={false}
    >
      {({
        handleSubmit,
        isValid,
        values,
        handleBlur,
        handleChange,
        setErrors,
        errors,
      }): ReactElement => {
        return (
          <Form>
            <Page
              title='Forgot password'
              buttonText='Email me'
              buttonAction={handleSubmit}
              buttonDisabled={!isValid || emailValid === false}
              buttonIsLoading={recoveryLoading}
              bottomContent={
                <FieldSubtitle
                  icon={QuestionMarkIcon}
                  text="I can't remember any of these details"
                  onClick={(): void => redirect('/lost')}
                />
              }
            >
              <styles.Text>
                We will send you an email with a magic link to reset your password
              </styles.Text>
              <Field
                id='loginEmail'
                name='username'
                type='email'
                error={
                  emailValid === false
                    ? 'We cannot find an account with that email address'
                    : undefined
                }
                placeholder='name@example.com'
                value={values.username}
                validate={isEmail}
                onBlur={handleBlur}
                onChange={(e: Event): void => {
                  handleChange(e);
                  if (emailValid === false || errors.username) {
                    invalidateEmail();
                    setErrors({});
                  }
                }}
                component={InputField}
              />
            </Page>
          </Form>
        );
      }}
    </Formik>
  );
};

export default PasswordForgot;
