/* eslint-disable @typescript-eslint/no-explicit-any */
import { FieldProps } from 'formik';
import React from 'react';

import { Input } from '@rentecarlo/component-library';

interface InputFieldInterface extends FieldProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  name: string;
  error?: string;
}

type ErrorText = string | undefined;

// Formik Field Input handler
const InputField: React.FC<InputFieldInterface> = ({
  field,
  form,
  placeholder,
  error,
  ...others
}) => {
  //  Cast FieldError from FormikError<any> to a handled
  //  String | Undefined so our generic input is agnostic to Formik.
  const errorText = form.errors[field.name] as ErrorText;
  const touched = form.touched[field.name];

  return (
    <Input
      placeholder={placeholder}
      touched={!!touched}
      errorText={errorText || error}
      {...field}
      {...others}
    />
  );
};

export default InputField;
