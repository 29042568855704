import { SagaIterator } from '@redux-saga/core';
import { takeEvery, put, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { CognitoClient } from '@rentecarlo/node-amplify-client';

import { AccountClient } from 'utils/accountClient';

import { performRedirect } from 'state/redirect/actions';

import { CHECK_EMAIL_REQUEST, CheckEmailAction, LOGIN_REQUEST, LoginRequestAction } from './types';

import { checkEmailFailure, checkEmailSuccess, loginFailure, loginSuccess } from './actions';

export function* checkEmailSaga(action: CheckEmailAction): SagaIterator {
  try {
    yield call(AccountClient.checkEmail, {
      email: action.email.toLowerCase(),
    });

    yield put(checkEmailSuccess());
  } catch (exception) {
    if ((exception as Response).status === 429) {
      // connection is throttled
      yield put(push('matrix'));
    }
    yield put(checkEmailFailure());
  }
}

export function* loginSaga(action: LoginRequestAction): SagaIterator {
  try {
    try {
      yield call(() => CognitoClient.signOut());
    } catch (error) {
      // sign out from any existing Cognito sessions prior to sign in
    }
    yield call(() => CognitoClient.signIn(action.username, action.password));
    yield put(loginSuccess());
    yield put(performRedirect());
  } catch (error) {
    yield put(loginFailure());
  }
}

export default function* saga(): SagaIterator {
  yield takeEvery(CHECK_EMAIL_REQUEST, checkEmailSaga);
  yield takeEvery(LOGIN_REQUEST, loginSaga);
}
