import { SagaIterator } from 'redux-saga';
import { takeEvery, select } from 'redux-saga/effects';

import { AppState } from 'state';
import { PERFORM_REDIRECT } from 'state/redirect/types';

export const redirectUrl = (state: AppState): string =>
  state.redirect.url || `${process.env.REACT_APP_CMS_URL}/?loggedin` || '';

const ALLOWED_DOMAINS = ['preprod-veygo.com', 'veygo.com', 'veygo.lol'];
const ALLOWED_SUBDOMAINS = ['account', 'cms', 'preview.cms', 'quote', 'www'];

const ALLOWED_HOSTS: string[] = [];

for (let a = 0; a < ALLOWED_DOMAINS.length; a += 1) {
  ALLOWED_HOSTS.push(ALLOWED_DOMAINS[a]);
}

for (let a = 0; a < ALLOWED_DOMAINS.length; a += 1) {
  for (let b = 0; b < ALLOWED_SUBDOMAINS.length; b += 1) {
    ALLOWED_HOSTS.push(`${ALLOWED_SUBDOMAINS[b]}.${ALLOWED_DOMAINS[a]}`);
  }
}

export function* performRedirect(): SagaIterator {
  const url: string = yield select(redirectUrl);
  const urlObject: URL = new URL(url);
  if (!ALLOWED_HOSTS.includes(urlObject.hostname)) {
    window.location.assign(process.env.REACT_APP_CMS_URL || 'https://veygo.com/');
  } else {
    window.location.assign(url);
  }
}

export default function* redirectSaga(): SagaIterator {
  yield takeEvery(PERFORM_REDIRECT, performRedirect);
}
