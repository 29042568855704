import {
  SEND_EMAIL_REQUEST,
  SEND_EMAIL_FAILURE,
  SEND_EMAIL_SUCCESS,
  SET_TOKEN,
  SET_CONFIRMATION_CODE,
  SET_USERNAME,
  RecoveryActionTypes,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_SUCCESS,
  VALIDATE_JWT_REQUEST,
  VALIDATE_JWT_FAILURE,
  VALIDATE_JWT_SUCCESS,
  USERNAME_REMINDER_FAILURE,
  USERNAME_REMINDER_REQUEST,
  USERNAME_REMINDER_SUCCESS,
  Error,
} from './types';

export const sendEmailRequest = (email: string): RecoveryActionTypes => ({
  type: SEND_EMAIL_REQUEST,
  email,
});

export const sendEmailFailure = (): RecoveryActionTypes => ({
  type: SEND_EMAIL_FAILURE,
});

export const sendEmailSuccess = (): RecoveryActionTypes => ({
  type: SEND_EMAIL_SUCCESS,
});

export const setToken = (token: string | null): RecoveryActionTypes => ({
  type: SET_TOKEN,
  token,
});

export const setConfirmationCode = (confirmationCode: string | null): RecoveryActionTypes => ({
  type: SET_CONFIRMATION_CODE,
  confirmationCode,
});

export const setUsername = (username: string | null): RecoveryActionTypes => ({
  type: SET_USERNAME,
  username,
});

export const resetPasswordRequest = (newPassword: string): RecoveryActionTypes => ({
  type: RESET_PASSWORD_REQUEST,
  newPassword,
});

export const resetPasswordFailure = (): RecoveryActionTypes => ({
  type: RESET_PASSWORD_FAILURE,
});

export const resetPasswordSuccess = (): RecoveryActionTypes => ({
  type: RESET_PASSWORD_SUCCESS,
});

export const usernameReminderRequest = (dln: string): RecoveryActionTypes => ({
  type: USERNAME_REMINDER_REQUEST,
  dln,
});

export const usernameReminderSuccess = (redactedEmail: string): RecoveryActionTypes => ({
  type: USERNAME_REMINDER_SUCCESS,
  redactedEmail,
});

export const usernameReminderFailure = (error: Error): RecoveryActionTypes => ({
  type: USERNAME_REMINDER_FAILURE,
  error,
});

export const validateJWTTokenRequest = (token: string): RecoveryActionTypes => ({
  type: VALIDATE_JWT_REQUEST,
  token,
});

export const validateJWTTokenSuccess = (): RecoveryActionTypes => ({
  type: VALIDATE_JWT_SUCCESS,
});

export const validateJWTTokenFailure = (error: Error): RecoveryActionTypes => ({
  type: VALIDATE_JWT_FAILURE,
  error,
});

export type ResetActions =
  | ReturnType<typeof sendEmailRequest>
  | ReturnType<typeof sendEmailFailure>
  | ReturnType<typeof sendEmailSuccess>
  | ReturnType<typeof setToken>
  | ReturnType<typeof resetPasswordRequest>
  | ReturnType<typeof resetPasswordFailure>
  | ReturnType<typeof resetPasswordSuccess>;
