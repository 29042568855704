import { call, put } from 'redux-saga/effects';
import { AmplifyService } from 'services';
import uuid from 'uuid/v4';
import { setSessionCookie, getCookie } from 'utils/cookies';
import { setOptimizelyAttribute } from './config/actions';

function* setUserIdentifier() {
  let userIdentity = getCookie('user_identity');

  if (userIdentity === undefined) {
    userIdentity = uuid();

    const cookieYesCookie = getCookie('cookieyes-consent');
    if (cookieYesCookie && cookieYesCookie.includes('functional:yes')) {
      setSessionCookie('user_identity', userIdentity as string);
    }
  }

  yield put(setOptimizelyAttribute('user_identity', userIdentity as string));
}

function* initialiseAmplifyService() {
  try {
    yield call(() => AmplifyService.initialise());
  } catch (error) {
    console.error(error); // eslint-disable-line no-console
  }
}

export function* runInit(): Generator {
  yield setUserIdentifier();
  yield initialiseAmplifyService();
}
