import React, { ReactElement, useState } from 'react';
import { Formik, Field, Form } from 'formik';
import { TextLink, FieldSubtitle, Tooltip, TooltipEvents } from '@rentecarlo/component-library';

import { InputField } from 'components/atoms';
import { Page } from 'components/templates';

import { Error } from 'state/recovery/types';
import { isDLNValid } from 'utils/formValidation';
import QuestionMarkIcon from 'assets/field-subtitle-question-mark.svg';
import FullDLN from 'assets/full-dln.png';
import * as Yup from 'yup';
import { LineContainer, Text, ErrorBox, IconContainer, DLNImage } from './assets/styles';

interface Props {
  requestUsernameReminder: (dln: string) => void;
  error: Error | null;
  redirectUrl: string;
  redirect: (url: string) => void;
  recoveryLoading: boolean;
}

interface FormValues {
  dln: string;
}

const FormSchema = Yup.object().shape({
  dln: Yup.string()
    .required('This text box feels incomplete without your driving licence number 😞')
    .test(
      'Is DLN valid',
      "Hmm... something's not right. Please double check the licence number and try again.",
      (dln) => isDLNValid(dln),
    ),
});

const EmailForgot = ({
  error,
  redirectUrl,
  requestUsernameReminder,
  redirect,
  recoveryLoading,
}: Props): ReactElement => {
  const [tooltipEvents] = useState(new TooltipEvents());
  const hasError = error && error.code === 'CUSTOMER_NOT_FOUND';
  return (
    <Formik
      initialValues={{ dln: '' }}
      onSubmit={({ dln }: FormValues): void => requestUsernameReminder(dln)}
      validateOnChange={false}
      validationSchema={FormSchema}
    >
      {({ handleSubmit, values, handleBlur, handleChange, isValid, setErrors }): ReactElement => {
        return (
          <Form>
            <Page
              title='Forgot your email'
              buttonText='Remind me'
              buttonAction={handleSubmit}
              buttonDisabled={!isValid}
              buttonIsLoading={recoveryLoading}
              bottomContent={
                <FieldSubtitle
                  icon={QuestionMarkIcon}
                  text="I can't remember any of these details"
                  onClick={(): void => redirect('/lost')}
                />
              }
            >
              <LineContainer>
                <Text>
                  At sign up, we asked for the driver's licence number. Type it in below to see a
                  reminder of the email address for the account:
                </Text>
                <IconContainer>
                  <Tooltip
                    title='Help'
                    modalTitle='Help'
                    tooltipDirection='right'
                    events={tooltipEvents}
                  >
                    <DLNImage src={FullDLN} />
                    <p>We only need the details shown in the red box.</p>
                  </Tooltip>
                </IconContainer>
              </LineContainer>
              <Field
                id='dln'
                name='dln'
                error={
                  hasError ? (
                    <ErrorBox>
                      Hmm... we can't find an account linked to that licence number. Your Veygo
                      account will be created when you buy a policy - click{' '}
                      <TextLink fontSize={14} href={redirectUrl}>
                        here
                      </TextLink>{' '}
                      to get a quote!
                    </ErrorBox>
                  ) : undefined
                }
                placeholder='This is a 16-digit number'
                alwaysCapitalised
                value={values.dln}
                onBlur={handleBlur}
                onChange={(e: Event): void => {
                  handleChange(e);
                  setErrors({});
                }}
                component={InputField}
              />
            </Page>
          </Form>
        );
      }}
    </Formik>
  );
};

export default EmailForgot;
