import React, { ReactElement, useEffect } from 'react';
import { Formik, Field, Form } from 'formik';
import { FieldWrapper } from '@rentecarlo/component-library';
import { PasswordInputField } from 'components/atoms';
import { Page } from 'components/templates';
import { isPasswordSame, Validation, isPasswordSecure } from 'utils/formValidation';
import { ComponentProps } from './PasswordReset.container';
import * as styles from './assets/styles';

export interface FormValues {
  password: string;
  passwordConfirm: string;
}

const PasswordReset = (props: ComponentProps): ReactElement => {
  const { setToken, setConfirmationCode, setUsername, resetPassword, recoveryLoading } = props;
  const initialValues: FormValues = {
    password: '',
    passwordConfirm: '',
  };
  useEffect(() => {
    if (window.location.search) {
      setToken(window.location.search);
      setConfirmationCode(window.location.search);
      setUsername(window.location.search);
    }
  });
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values: FormValues): void => resetPassword(values.passwordConfirm)}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({
        handleSubmit,
        isValid,
        values,
        handleBlur,
        handleChange,
        setErrors,
        validateField,
        errors,
      }): ReactElement => {
        return (
          <Form>
            <Page
              title='Reset your password'
              buttonText='Reset password'
              buttonAction={handleSubmit}
              buttonDisabled={!isValid}
              buttonIsLoading={recoveryLoading}
            >
              <FieldWrapper title='New password'>
                <Field
                  id='resetPassword'
                  name='password'
                  placeholder=''
                  value={values.password}
                  validate={isPasswordSecure}
                  onChange={(e: Event): void => {
                    handleChange(e);
                    setErrors({});
                  }}
                  onBlur={(e: Event): void => {
                    handleBlur(e);
                    validateField('password');
                  }}
                  component={PasswordInputField}
                />
              </FieldWrapper>
              <styles.Spacer />
              <FieldWrapper title='Retype password'>
                <Field
                  id='resetPasswordConfirm'
                  name='passwordConfirm'
                  placeholder=''
                  disabled={errors.password}
                  value={values.passwordConfirm}
                  validate={(): Validation =>
                    isPasswordSame(values.password, values.passwordConfirm)
                  }
                  onChange={(e: Event): void => {
                    handleChange(e);
                    setErrors({});
                  }}
                  onBlur={(e: Event): void => {
                    handleBlur(e);
                    validateField('passwordConfirm');
                  }}
                  component={PasswordInputField}
                />
              </FieldWrapper>
            </Page>
          </Form>
        );
      }}
    </Formik>
  );
};

export default PasswordReset;
