import Styled from 'styled-components';

export const LineContainer = Styled.div`
  display: flex;
  align-items: top;
  font-family: proxima-soft;
  font-size: 16px;
  color: #364344;
`;

export const Text = Styled.div`
  line-height: 20px;
  margin-bottom: 16px;
`;

export const ErrorBox = Styled.span`
  font-family: proxima-soft;
  font-size: 14px;
`;

export const IconContainer = Styled.div`
  display: flex;
  position: relative;
  align-items: center;
  width: 16px;
  height: 16px;
  margin-right: 7px;
`;

export const DLNImage = Styled.img`
  height: 140px;
  width: 250px;
  margin-left: -15px;
`;
