import React, { ReactElement } from 'react';

import { Page } from 'components/templates';

import EmailSent from './assets/email-sent.svg';
import * as styles from './assets/styles';

const PasswordResetEmailSent = (): ReactElement => {
  return (
    <Page
      title='An email is on its way!'
      titleCentered
      buttonText='Back to homepage'
      buttonAction={(): void => {
        const link = process.env.REACT_APP_HOMEPAGE || process.env.REACT_APP_CMS_URL;
        window.location.href = link || '';
      }}
    >
      <styles.Container>
        <styles.Image src={EmailSent} />
        <styles.Text>
          If we've built this correctly you should get an email within the next few minutes. If you
          don't, maybe check your spam, junk or promotions folder. If you're still having trouble,
          <styles.Link href='https://www.veygo.com/contact'> contact us</styles.Link>.
        </styles.Text>
      </styles.Container>
    </Page>
  );
};

export default PasswordResetEmailSent;
