import React, { ReactElement } from 'react';

import * as styles from './assets/styles';

interface Props {
  text: string;
}

const TextDivider = (props: Props): ReactElement => {
  const { text } = props;
  return (
    <styles.StyledContainer>
      <styles.Line />
      <styles.Text>{text}</styles.Text>
      <styles.Line />
    </styles.StyledContainer>
  );
};

export default TextDivider;
