import Styled from 'styled-components';
import { MediaQuery } from '@rentecarlo/component-library';

export const StyledContainer = Styled.div`
  min-width: 100vw;
  min-height: 100vh;
  background-color: #FBFBFC;
`;

export const StyledContent = Styled.div`
  margin: 0 auto;
  padding-top: 56px;
  min-width: 320px;
  max-width: 560px;
  display: flex;
  flex-direction: column;
`;

export const StyledBox = Styled.div`
  margin: 32px 40px 40px 40px;
  border-radius: 8px;
	background-color: #FFFFFF;
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.08);
  padding: 40px;
  max-width: 560px;
  ${MediaQuery.mobile`
    margin: 32px 8px 40px 8px;
    padding: 24px 16px 40px 16px;
  `}
`;

export interface StyledTitleProps {
  centered: boolean;
}

export const StyledTitle = Styled.h1`
	color: #364344;
	font-family: Graphie;
	font-size: 32px;
  font-weight: bold;
  text-align: ${({ centered }: StyledTitleProps): string => (centered ? 'center' : 'left')};
  ${MediaQuery.mobile`
    text-align: center;
  `}
`;

export const StyledSubtitle = Styled.div`
  display: flex;
  justify-content: center;
  padding-top: 16px;
`;

export const StyledBoxContent = Styled.div`
  margin: 32px 0px 40px 0px;
`;

export const StyledButtonContainer = Styled.div`
  justify-self: flex-end;
`;

export const StyledBottomContent = Styled.div`
  margin: 0px 40px;
  ${MediaQuery.mobile`
    margin: 0px 16px;
  `}
`;

export const StyledLogoWrapper = Styled.a.attrs(() => ({
  href: process.env.REACT_APP_HOMEPAGE,
}))`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledLogo = Styled.img.attrs(() => ({
  alt: 'Veygo logo',
  rel: 'noopener noreferrer',
  height: '45px',
  width: '162px',
}))`
  cursor: pointer;
`;

export const StyledCardFooterContainer = Styled.div`
  margin-top: 24px;
`;
