import React, { ReactElement } from 'react';
import { Panel, TextLink } from '@rentecarlo/component-library';
import { Page } from 'components/templates';

import {
  StyledRedactedEmailWrapper,
  StyledSubtitleContainer,
  StyledSubtitle,
  StyledPanelContainer,
  StyledPanelWrapper,
} from './assets/styles';

interface Props {
  redactedEmail: string | null;
  redirect: () => void;
}

const CONTACT_URL = 'https://www.veygo.com/contact/';

const NearlyThere = ({ redactedEmail, redirect }: Props): ReactElement => {
  return (
    <Page title='Nearly there...' buttonText='Sign in' buttonAction={redirect}>
      <StyledSubtitleContainer>
        <StyledSubtitle>
          The driving licence number you’ve given us is linked to the email below. You can now use
          it to sign in. Don’t recognise it? <TextLink href={CONTACT_URL}>Contact us</TextLink>
        </StyledSubtitle>
      </StyledSubtitleContainer>
      <StyledPanelContainer>
        <Panel>
          <StyledPanelWrapper>
            <StyledRedactedEmailWrapper>{redactedEmail}</StyledRedactedEmailWrapper>
          </StyledPanelWrapper>
        </Panel>
      </StyledPanelContainer>
    </Page>
  );
};

export default NearlyThere;
