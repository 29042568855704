import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { RedirectActionTypes } from 'state/redirect/types';
import { setRedirectUrl } from 'state/redirect/actions';
import { getRedirectUrl } from 'utils/parseUrl';

import App from './App';

interface DispatchProps {
  setRedirectUrl: (url: string) => void;
}

export type ComponentProps = DispatchProps;

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  setRedirectUrl: (url: string): RedirectActionTypes =>
    dispatch(setRedirectUrl(getRedirectUrl(url))),
});

export default connect(null, mapDispatchToProps)(App);
