import React, { ReactElement } from 'react';

import { Page } from 'components/templates';

import SuccessVeypal from './assets/success-veypal.svg';
import * as styles from './assets/styles';

import { ComponentProps } from './PasswordResetSuccess.container';

const PasswordResetSuccess = (props: ComponentProps): ReactElement => {
  const { redirect, confirmationCode, redirectToSignIn } = props;
  return (
    <Page
      title='Success!'
      titleCentered
      buttonText={confirmationCode ? 'Sign in' : 'Get a quote'}
      buttonAction={confirmationCode ? (): void => redirectToSignIn() : (): void => redirect()}
    >
      <styles.Container>
        <styles.Image src={SuccessVeypal} />
        <styles.Text>
          You’re back in the game! You’re all set and ready to start using your account again.
        </styles.Text>
      </styles.Container>
    </Page>
  );
};

export default PasswordResetSuccess;
