import { connectRouter } from 'connected-react-router';
import { createBrowserHistory, History } from 'history';
import { combineReducers } from 'redux';

import { reducer as configReducer } from './config';
import { reducer as loginReducer } from './login';
import { reducer as recoveryReducer } from './recovery';
import { reducer as redirectReducer } from './redirect';

export const history = createBrowserHistory();

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const createRootReducer = (previousHistory: History) =>
  combineReducers({
    config: configReducer,
    login: loginReducer,
    recovery: recoveryReducer,
    redirect: redirectReducer,
    router: connectRouter(previousHistory),
  });

export default createRootReducer(history);
