export type Validation = string | undefined | boolean;

const matchesEmailRegex = (email: string): Validation =>
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email,
  );

export const isEmail = (value: string): Validation => {
  if (matchesEmailRegex(value)) {
    return undefined;
  }
  return 'Please enter a valid email address';
};

export const isRequired = (value: string): Validation => {
  if (value.length > 0) {
    // Prefered to return undefined than null
    // as per best practice on Formik's validation docs.
    return undefined;
  }

  return 'This is a required field.';
};

export const isRequiredPassword = (value: string): Validation => {
  if (value.length > 0) {
    return undefined;
  }

  return "Ah ah ah, you didn't say the magic word";
};

export const isPasswordSame = (password: string, passwordConfirm: string): Validation => {
  if (password === passwordConfirm) {
    return undefined;
  }

  return 'The passwords you have entered do not match';
};

const securePasswordRegex = /^(?=.*[A-Za-z])(?=.*\d).{8,}$/;

export const isPasswordSecure = (password: string): Validation => {
  if (securePasswordRegex.test(password)) {
    return undefined;
  }
  return 'Your password needs to be at least 8 characters with at least one number and one letter';
};

const validDLNRegex = /^[A-Za-z9]{5}\d[0156]\d([0][1-9]|[12]\d|3[01])\d[A-Za-z]{1}[A-Za-z9]{1}[0-9]{1}[A-Za-z]{2}$/;

export const isDLNValid = (dln: string): boolean => {
  return validDLNRegex.test(dln);
};
