import {
  CHECK_EMAIL_REQUEST,
  CHECK_EMAIL_FAILURE,
  CHECK_EMAIL_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  INVALIDATE_EMAIL,
  INVALIDATE_PASSWORD,
  RESET_LOGIN,
  LoginActionTypes,
} from './types';

export const checkEmailRequest = (email: string): LoginActionTypes => ({
  type: CHECK_EMAIL_REQUEST,
  email,
});

export const checkEmailFailure = (): LoginActionTypes => ({
  type: CHECK_EMAIL_FAILURE,
});

export const checkEmailSuccess = (): LoginActionTypes => ({
  type: CHECK_EMAIL_SUCCESS,
});

export const loginRequest = (username: string, password: string): LoginActionTypes => ({
  type: LOGIN_REQUEST,
  username,
  password,
});

export const loginFailure = (): LoginActionTypes => ({
  type: LOGIN_FAILURE,
});

export const loginSuccess = (): LoginActionTypes => ({
  type: LOGIN_SUCCESS,
});

export const invalidateEmail = (): LoginActionTypes => ({
  type: INVALIDATE_EMAIL,
});

export const invalidatePassword = (): LoginActionTypes => ({
  type: INVALIDATE_PASSWORD,
});

export const resetLogin = (): LoginActionTypes => ({
  type: RESET_LOGIN,
});

export type LoginActions =
  | ReturnType<typeof checkEmailRequest>
  | ReturnType<typeof checkEmailFailure>
  | ReturnType<typeof checkEmailSuccess>
  | ReturnType<typeof loginRequest>
  | ReturnType<typeof loginFailure>
  | ReturnType<typeof loginSuccess>
  | ReturnType<typeof resetLogin>;
