import * as queryString from 'query-string';

interface ParsedSearchString {
  [key: string]: any; // eslint-disable-line
}

export const getRedirectUrl = (search: string): string | undefined => {
  const parsed: ParsedSearchString = queryString.parse(search);
  return parsed.redirect || undefined;
};

export const getTokenFromUrl = (search: string): string | null => {
  const parsed: ParsedSearchString = queryString.parse(search);
  return parsed.token || null;
};

export const getConfirmationCodeFromUrl = (search: string): string | null => {
  const parsed: ParsedSearchString = queryString.parse(search);
  return parsed.confirmation_code || null;
};

export const getUsernameFromUrl = (search: string): string | null => {
  const parsed: ParsedSearchString = queryString.parse(search, { decode: false });
  return parsed.username || null;
};

export const getLoginPointFromRedirectUrl = (url: string): string => {
  return url.substr(url.lastIndexOf('/'));
};
