import * as Sentry from '@sentry/react';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { OptimizelyProvider, createInstance } from '@optimizely/react-sdk';
import './index.css';
import { Provider } from 'react-redux';
import store from 'state/store';
import App from './App.container';
import * as serviceWorker from './serviceWorker';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
});

const optimizelyClient = createInstance({
  sdkKey: process.env.REACT_APP_OPTIMIZELY_KEY,
});

const container = document.getElementById('root') as Element;
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <OptimizelyProvider optimizely={optimizelyClient} user={{ id: 'tempcover_user' }}>
      <App />
    </OptimizelyProvider>
  </Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
