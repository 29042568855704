export type RedirectState = Readonly<{
  url?: string;
}>;

export const SET_REDIRECT_URL = 'SET_REDIRECT_URL';
export const PERFORM_REDIRECT = 'PERFORM_REDIRECT';

interface SetRedirectUrlAction {
  type: typeof SET_REDIRECT_URL;
  url: string | undefined;
}

interface PerformRedirectAction {
  type: typeof PERFORM_REDIRECT;
}

export type RedirectActionTypes = SetRedirectUrlAction | PerformRedirectAction;
