import {
  RecoveryState,
  RecoveryActionTypes,
  SEND_EMAIL_REQUEST,
  SEND_EMAIL_FAILURE,
  SEND_EMAIL_SUCCESS,
  SET_TOKEN,
  SET_CONFIRMATION_CODE,
  SET_USERNAME,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_SUCCESS,
  VALIDATE_JWT_REQUEST,
  VALIDATE_JWT_SUCCESS,
  VALIDATE_JWT_FAILURE,
  USERNAME_REMINDER_FAILURE,
  USERNAME_REMINDER_REQUEST,
  USERNAME_REMINDER_SUCCESS,
} from './types';

const initialState: RecoveryState = {
  error: null,
  loading: false,
  token: null,
  redactedEmail: null,
  dln: '',
  confirmationCode: null,
  username: null,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
function reset(state: RecoveryState = initialState, action: RecoveryActionTypes): RecoveryState {
  switch (action.type) {
    case SEND_EMAIL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SEND_EMAIL_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case SEND_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case SET_TOKEN:
      return {
        ...state,
        token: action.token,
      };
    case SET_CONFIRMATION_CODE:
      return {
        ...state,
        confirmationCode: action.confirmationCode,
      };
    case SET_USERNAME:
      return {
        ...state,
        username: action.username,
      };
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case USERNAME_REMINDER_REQUEST:
      return {
        ...state,
        loading: true,
        dln: action.dln,
      };
    case USERNAME_REMINDER_SUCCESS:
      return {
        ...state,
        loading: false,
        redactedEmail: action.redactedEmail,
      };
    case USERNAME_REMINDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case VALIDATE_JWT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case VALIDATE_JWT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case VALIDATE_JWT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export default reset;
