export type LoginState = Readonly<{
  email: {
    value: string;
    valid: boolean | undefined;
    loading: boolean;
  };
  password: {
    valid: boolean | undefined;
    loading: boolean;
  };
}>;

export const CHECK_EMAIL_REQUEST = 'CHECK_EMAIL.REQUEST';
export const CHECK_EMAIL_FAILURE = 'CHECK_EMAIL.FAILURE';
export const CHECK_EMAIL_SUCCESS = 'CHECK_EMAIL.SUCCESS';

export const LOGIN_REQUEST = 'LOGIN.REQUEST';
export const LOGIN_FAILURE = 'LOGIN.FAILURE';
export const LOGIN_SUCCESS = 'LOGIN.SUCCESS';

export const INVALIDATE_EMAIL = 'INVALIDATE_EMAIL';
export const INVALIDATE_PASSWORD = 'INVALIDATE_PASSWORD';
export const RESET_LOGIN = 'LOGIN.RESET';

export interface CheckEmailAction {
  type: typeof CHECK_EMAIL_REQUEST;
  email: string;
}

export interface CheckEmailFailureAction {
  type: typeof CHECK_EMAIL_FAILURE;
}

export interface CheckEmailSuccessAction {
  type: typeof CHECK_EMAIL_SUCCESS;
}

export interface LoginRequestAction {
  type: typeof LOGIN_REQUEST;
  username: string;
  password: string;
}

export interface LoginFailureAction {
  type: typeof LOGIN_FAILURE;
}

export interface LoginSuccessAction {
  type: typeof LOGIN_SUCCESS;
}

interface InvalidateEmailAction {
  type: typeof INVALIDATE_EMAIL;
}

interface InvalidatePasswordAction {
  type: typeof INVALIDATE_PASSWORD;
}

export interface ResetLoginAction {
  type: typeof RESET_LOGIN;
}

export type LoginActionTypes =
  | CheckEmailAction
  | CheckEmailFailureAction
  | CheckEmailSuccessAction
  | LoginRequestAction
  | LoginFailureAction
  | LoginSuccessAction
  | InvalidateEmailAction
  | InvalidatePasswordAction
  | ResetLoginAction;
