import { TEST_ACTION, SET_OPTIMIZELY_ATTRIBUTE, ConfigState, ConfigActionTypes } from './types';

export const initialState: ConfigState = {
  optimizelyAttributes: {},
  test: '',
};

function config(state: ConfigState = initialState, action: ConfigActionTypes): ConfigState {
  switch (action.type) {
    case TEST_ACTION:
      return {
        ...state,
        test: action.value
      }
    case SET_OPTIMIZELY_ATTRIBUTE:
      return {
        ...state,
        optimizelyAttributes: {
          ...state.optimizelyAttributes,
          [action.key]: action.value,
        }
      }
    default:
      return state;
  }
}

export default config;
