import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { push, CallHistoryMethodAction } from 'connected-react-router';
import { AppState } from 'state';
import { RecoveryActionTypes, Error } from 'state/recovery/types';
import { usernameReminderRequest } from 'state/recovery/actions';
import { redirectUrl } from 'state/redirect/sagas';
import EmailForgot from './EmailForgot';

interface DispatchProps {
  requestUsernameReminder: (dln: string) => void;
  redirect: (url: string) => void;
}

interface StateProps {
  error: Error | null;
  redirectUrl: string;
  recoveryLoading: boolean;
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  requestUsernameReminder: (dln: string): RecoveryActionTypes =>
    dispatch(usernameReminderRequest(dln)),
  redirect: (url: string): CallHistoryMethodAction => dispatch(push(url)),
});

const mapStateToProps = (state: AppState): StateProps => ({
  error: state.recovery.error,
  redirectUrl: redirectUrl(state),
  recoveryLoading: state.recovery.loading,
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailForgot);
