import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';

import rootSaga from './saga';
import rootReducer from './rootReducer';

export const history = createBrowserHistory();

const sagaMiddeware = createSagaMiddleware();

const composeEnhancers = composeWithDevTools({});

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(sagaMiddeware, routerMiddleware(history))),
);

sagaMiddeware.run(rootSaga);

export default store;
