export type ConfigState = Readonly<{
  optimizelyAttributes: Record<string, string>,
  test: string;
}>;

export const TEST_ACTION = "TEST_ACTION";
export const TEST_SUCCESS_ACTION = "TEST_SUCCESS_ACTION";
export const FEATURE_FLAGS_SUCCESS =  "FEATURE_FLAGS.SUCCESS";
export const SET_OPTIMIZELY_ATTRIBUTE =  "SET_OPTIMIZELY_ATTRIBUTE";

interface TestAction {
  type: typeof TEST_ACTION;
  value: string;
}

interface TestSuccessAction {
  type: typeof TEST_SUCCESS_ACTION;
}

interface FetchFeatureFlagsAction {
  type: typeof FEATURE_FLAGS_SUCCESS;
  featureFlags: Object;
}

export interface SetOptimizelyAttribute {
  type: typeof SET_OPTIMIZELY_ATTRIBUTE;
  key: string;
  value: string;
}

export type ConfigActionTypes =
  | TestAction
  | TestSuccessAction
  | FetchFeatureFlagsAction
  | SetOptimizelyAttribute;
