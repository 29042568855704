import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { push, CallHistoryMethodAction } from 'connected-react-router';

import { AppState } from 'state';
import {
  checkEmailRequest,
  loginRequest,
  invalidateEmail,
  invalidatePassword,
  resetLogin,
} from 'state/login/actions';
import { LoginActionTypes } from 'state/login/types';

import Login, { FormValues } from './Login';

interface StateProps {
  email: string;
  emailValid: boolean | undefined;
  emailLoading: boolean;
  passwordValid: boolean | undefined;
  passwordLoading: boolean;
}

interface DispatchProps {
  dispatchSubmit: (emailValid: boolean | undefined, values: FormValues) => void;
  invalidateEmail: () => void;
  invalidatePassword: () => void;
  resetLogin: () => void;
  redirect: (url: string) => void;
}

interface MergeProps {
  submit: (values: FormValues) => void;
}

export interface ComponentProps extends StateProps, DispatchProps, MergeProps {}

const submitForm = (
  dispatch: Dispatch<LoginActionTypes>,
  emailValid: boolean | undefined,
  values: FormValues,
): void => {
  if (emailValid) {
    dispatch(loginRequest(values.username, values.password));
  } else {
    dispatch(invalidatePassword());
    dispatch(checkEmailRequest(values.username));
  }
};

const mapStateToProps = (state: AppState): StateProps => ({
  email: state.login.email.value,
  emailValid: state.login.email.valid,
  emailLoading: state.login.email.loading,
  passwordValid: state.login.password.valid,
  passwordLoading: state.login.password.loading,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  dispatchSubmit: (emailValid: boolean | undefined, values: FormValues): void =>
    submitForm(dispatch, emailValid, values),
  invalidateEmail: (): LoginActionTypes => dispatch(invalidateEmail()),
  invalidatePassword: (): LoginActionTypes => dispatch(invalidatePassword()),
  resetLogin: (): LoginActionTypes => dispatch(resetLogin()),
  redirect: (url: string): CallHistoryMethodAction => dispatch(push(url)),
});

const mergeProps = (stateProps: StateProps, dispatchProps: DispatchProps, ownProps: any): MergeProps => ({ // eslint-disable-line
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  submit: (values: FormValues): void => dispatchProps.dispatchSubmit(stateProps.emailValid, values),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Login);
