import React, { ReactElement, useEffect } from 'react';
import { Page } from 'components/templates';
import RoadClosed from './assets/road-closed-mobile.svg';
import * as styles from './assets/styles';
import { ComponentProps } from './PasswordResetLinkExpired.container';

const PasswordResetLinkExpired = (props: ComponentProps): ReactElement => {
  const {
    username,
    sendEmailRequest,
    redirectToPasswordForgot,
    invalidEmail,
    redirectToMatrix,
    recoveryLoading,
  } = props;
  const pageTitle = 'Your password reset link has expired';
  const pageMessage =
    'Bad news is we have a 1hr time limit on a password reset request. Good news is you can request another.';

  // If a customer alters the email in the url to an email
  // that doesn't exist in Cognito. Push customer to Matrix
  useEffect(() => {
    if (invalidEmail !== undefined && !invalidEmail) {
      redirectToMatrix();
    }
  });

  return username ? (
    <Page
      title={pageTitle}
      titleCentered
      buttonText='Resend email'
      buttonAction={() => {
        sendEmailRequest(username);
      }}
      buttonIsLoading={recoveryLoading}
    >
      <styles.Container>
        <styles.Image src={RoadClosed} />
        <styles.Text>{pageMessage}</styles.Text>
      </styles.Container>
    </Page>
  ) : (
    <Page
      title={pageTitle}
      titleCentered
      buttonText='Request another email'
      buttonAction={() => {
        redirectToPasswordForgot();
      }}
    >
      <styles.Container>
        <styles.Image src={RoadClosed} />
        <styles.Text>{pageMessage}</styles.Text>
      </styles.Container>
    </Page>
  );
};

export default PasswordResetLinkExpired;
