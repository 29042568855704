import { SET_REDIRECT_URL, RedirectState, RedirectActionTypes } from './types';

const initialState: RedirectState = {
  url: undefined,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
function redirect(state: RedirectState = initialState, action: RedirectActionTypes): RedirectState {
  switch (action.type) {
    case SET_REDIRECT_URL:
      return {
        ...state,
        url: action.url,
      };
    default:
      return state;
  }
}

export default redirect;
