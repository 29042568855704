import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { AppState } from 'state';
import { push, CallHistoryMethodAction } from 'connected-react-router';

import NearlyThere from './NearlyThere';

interface StateProps {
  redactedEmail: string | null;
}

interface DispatchProps {
  redirect: () => void;
}

const mapStateToProps = (state: AppState): StateProps => ({
  redactedEmail: state.recovery.redactedEmail,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  redirect: (): CallHistoryMethodAction => dispatch(push('/')),
});

export default connect(mapStateToProps, mapDispatchToProps)(NearlyThere);
