import styled from 'styled-components';
import { MediaQuery } from '@rentecarlo/component-library';

export const Text = styled.div`
  margin-top: 16px;
  margin-bottom: 24px;
  font-family: proxima-soft;
  font-size: 16px;
  color: #757575;
  line-height: 20px;
  text-align: center;
`;

export const ButtonContainer = styled.div`
  margin: 0px 40px;
  ${MediaQuery.mobile`
    margin: 0px 0px;
  `}
`;
