export interface Error {
  code: string;
  message: string;
}

export type RecoveryState = Readonly<{
  loading: boolean;
  token: string | null;
  error: Error | null;
  dln: string;
  redactedEmail: string | null;
  confirmationCode: string | null;
  username: string | null;
}>;

export const SEND_EMAIL_REQUEST = 'SEND_EMAIL.REQUEST';
export const SEND_EMAIL_FAILURE = 'SEND_EMAIL.FAILURE';
export const SEND_EMAIL_SUCCESS = 'SEND_EMAIL.SUCCESS';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_CONFIRMATION_CODE = 'SET_CONFIRMATION_CODE';
export const SET_USERNAME = 'SET_USERNAME';
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD.REQUEST';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD.FAILURE';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD.SUCCESS';
export const USERNAME_REMINDER_REQUEST = 'USERNAME_REMINDER.REQUEST';
export const USERNAME_REMINDER_SUCCESS = 'USERNAME_REMINDER.SUCCESS';
export const USERNAME_REMINDER_FAILURE = 'USERNAME_REMINDER.FAILURE';
export const VALIDATE_JWT_REQUEST = 'VALIDATE_JWT.REQUEST';
export const VALIDATE_JWT_SUCCESS = 'VALIDATE_JWT.SUCCESS';
export const VALIDATE_JWT_FAILURE = 'VALIDATE_JWT.FAILURE';

export interface SendEmailAction {
  type: typeof SEND_EMAIL_REQUEST;
  email: string;
}

interface SendEmailFailureAction {
  type: typeof SEND_EMAIL_FAILURE;
}

interface SendEmailSuccessAction {
  type: typeof SEND_EMAIL_SUCCESS;
}

interface SetTokenAction {
  type: typeof SET_TOKEN;
  token: string | null;
}

interface SetConfirmationCodeAction {
  type: typeof SET_CONFIRMATION_CODE;
  confirmationCode: string | null;
}

interface SetUsernameAction {
  type: typeof SET_USERNAME;
  username: string | null;
}

export interface ResetPasswordAction {
  type: typeof RESET_PASSWORD_REQUEST;
  newPassword: string;
}

interface ResetPasswordFailureAction {
  type: typeof RESET_PASSWORD_FAILURE;
}

interface ResetPasswordSuccessAction {
  type: typeof RESET_PASSWORD_SUCCESS;
}

export interface UsernameReminderRequest {
  type: typeof USERNAME_REMINDER_REQUEST;
  dln: string;
}

export interface UsernameReminderSuccess {
  type: typeof USERNAME_REMINDER_SUCCESS;
  redactedEmail: string;
}

export interface UsernameReminderFailure {
  type: typeof USERNAME_REMINDER_FAILURE;
  error: Error;
}

export interface ValidateJWTRequest {
  type: typeof VALIDATE_JWT_REQUEST;
  token: string;
}

export interface ValidateJWTSuccess {
  type: typeof VALIDATE_JWT_SUCCESS;
}

export interface ValidateJWTFailure {
  type: typeof VALIDATE_JWT_FAILURE;
  error: Error;
}

export type RecoveryActionTypes =
  | SendEmailAction
  | SendEmailFailureAction
  | SendEmailSuccessAction
  | SetTokenAction
  | SetConfirmationCodeAction
  | SetUsernameAction
  | ResetPasswordAction
  | ResetPasswordFailureAction
  | ResetPasswordSuccessAction
  | ValidateJWTRequest
  | ValidateJWTSuccess
  | ValidateJWTFailure
  | UsernameReminderFailure
  | UsernameReminderRequest
  | UsernameReminderSuccess;
