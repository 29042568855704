import { SagaIterator } from '@redux-saga/core';
import { take, takeEvery, put, call, select } from 'redux-saga/effects';
import { push, replace } from 'connected-react-router';
import { CognitoClient } from '@rentecarlo/node-amplify-client';
import { AppState } from 'state';
import { checkEmailRequest } from 'state/login/actions';
import {
  CHECK_EMAIL_FAILURE,
  CHECK_EMAIL_SUCCESS,
  CheckEmailFailureAction,
  CheckEmailSuccessAction,
} from 'state/login/types';
import { AccountClient } from 'utils/accountClient';
import { CustomersApiClient } from 'utils/customersApiClient';

import {
  SendEmailAction,
  SEND_EMAIL_REQUEST,
  RESET_PASSWORD_REQUEST,
  ResetPasswordAction,
  VALIDATE_JWT_REQUEST,
  ValidateJWTRequest,
  USERNAME_REMINDER_REQUEST,
  UsernameReminderRequest,
} from './types';

import {
  sendEmailFailure,
  sendEmailSuccess,
  resetPasswordFailure,
  resetPasswordSuccess,
  validateJWTTokenRequest,
  validateJWTTokenSuccess,
  validateJWTTokenFailure,
  usernameReminderSuccess,
  usernameReminderFailure,
} from './actions';

interface LocationChangeAction {
  type: string;
  payload: {
    location: {
      pathname: string;
      search?: string;
    };
  };
}

const resetPasswordToken = (state: AppState): string | null => state.recovery.token;
const resetPasswordConfirmationCode = (state: AppState): string | null =>
  state.recovery.confirmationCode;
const resetPasswordUsername = (state: AppState): string | null => state.recovery.username;
const retrieveCustomerDLN = (state: AppState): string => state.recovery.dln;

export function* sendEmailSaga(action: SendEmailAction): SagaIterator {
  try {
    yield put(checkEmailRequest(action.email));
    const checkEmailResponse: CheckEmailFailureAction | CheckEmailSuccessAction = yield take([
      CHECK_EMAIL_SUCCESS,
      CHECK_EMAIL_FAILURE,
    ]);
    if (checkEmailResponse.type === CHECK_EMAIL_SUCCESS) {
      yield call(AccountClient.sendEmail, {
        email: action.email,
      });
      yield put(sendEmailSuccess());
      yield put(push('/password/check-your-inbox'));
    } else {
      yield put(sendEmailFailure());
    }
  } catch (exception) {
    yield put(sendEmailFailure());
    yield put(push('/matrix'));
  }
}

export function* resetPasswordSaga(action: ResetPasswordAction): SagaIterator {
  const token = yield select(resetPasswordToken);
  const confirmationCode = yield select(resetPasswordConfirmationCode);
  const username = yield select(resetPasswordUsername);
  try {
    if (!token && !(confirmationCode && username)) {
      throw new Error('No token or confirmation code with username provided');
    }
    if (token) {
      // for legacy users who have not migrated to cognito yet.
      // Reset their password and then migrate them.
      const response = yield call(AccountClient.resetPassword, {
        token,
        password: action.newPassword,
      });
      yield call(CognitoClient.signIn, response.username, action.newPassword);
    } else if (confirmationCode && username) {
      yield call(CustomersApiClient.resetPassword, {
        username,
        confirmationCode,
        password: action.newPassword,
      });
    }
    yield put(resetPasswordSuccess());
    yield put(replace('/password/success'));
  } catch (exception) {
    yield put(resetPasswordFailure());
    yield put(replace('/password/reset-link-expired'));
  }
}

export function* usernameReminderSaga({ dln }: UsernameReminderRequest): SagaIterator {
  try {
    const response = yield call(AccountClient.retrieveRedactedEmail, {
      dln,
    });
    yield put(usernameReminderSuccess(response.email));
    yield put(push('/email/forgot/nearly-there'));
  } catch (error) {
    const responseError = {
      code: 'CUSTOMER_NOT_FOUND',
      message: 'Customer not found',
    };
    yield put(usernameReminderFailure(responseError));
  }
}

export function* accessRecoveryPagesSaga({ payload }: LocationChangeAction): SagaIterator {
  if (payload.location.pathname === '/password/reset') {
    const token = new URLSearchParams(payload.location.search).get('token') || '';
    yield put(validateJWTTokenRequest(token));
  }

  if (payload.location.pathname === '/email/forgot/nearly-there') {
    const dln = yield select(retrieveCustomerDLN);
    if (!dln) {
      yield put(replace('/email/forgot'));
    }
  }
}

export function* validateJWTTokenSaga({ token }: ValidateJWTRequest): SagaIterator {
  try {
    yield call(AccountClient.validateUserAuthorizationToken, { token });
    yield put(validateJWTTokenSuccess());
  } catch (error) {
    const response = yield call((error as Response).json);
    yield put(validateJWTTokenFailure(response.code));
    yield put(replace('/password/forgot'));
  }
}

export default function* saga(): SagaIterator {
  yield takeEvery(SEND_EMAIL_REQUEST, sendEmailSaga);
  yield takeEvery(RESET_PASSWORD_REQUEST, resetPasswordSaga);
  yield takeEvery(VALIDATE_JWT_REQUEST, validateJWTTokenSaga);
  yield takeEvery(USERNAME_REMINDER_REQUEST, usernameReminderSaga);
}
