import React, { ReactElement } from 'react';

import { Page } from 'components/templates';

import LostVeypal from './assets/lost-veypal.svg';
import * as styles from './assets/styles';

const Lost = (): ReactElement => {
  return (
    <Page
      title='It happens to the best of us…'
      titleCentered
      buttonText='Get help'
      buttonAction={(): void => window.location.assign('https://www.veygo.com/help/')}
    >
      <styles.Container>
        <styles.Image src={LostVeypal} />
        <styles.Text>
          From losing our keys to forgetting where the car's parked - it happens! Don't worry, we
          can help you{' '}
          <span role='img' aria-label='ok'>
            👌
          </span>
        </styles.Text>
        <styles.Text>Click the button below to get help.</styles.Text>
        <styles.Text>
          When you're on the page, you should see a little "help" button to the bottom left, which
          is a shortcut to live chat if we're open and free, or email if not.
        </styles.Text>
      </styles.Container>
    </Page>
  );
};

export default Lost;
