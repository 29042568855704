/* eslint-disable @typescript-eslint/explicit-function-return-type */
import Client from './client';

export type UserCredentials = {
  username: string;
  password: string;
  confirmationCode: string;
};

const resetPassword = (data: UserCredentials): Promise<unknown> => {
  const customerApiBaseUrl = process.env.REACT_APP_CUSTOMERS_API_BASE_URL;
  return new Client.Builder().post(`${customerApiBaseUrl}/password-reset`).data(data).execute();
};

export const CustomersApiClient = {
  resetPassword,
};
