/* eslint-disable @typescript-eslint/explicit-function-return-type */

import Client from './client';
import { buildRequestUrl } from './clientUtils';

export type CheckEmail = {
  email: string;
};

export type SendEmail = {
  email: string;
};

export type ResetPassword = {
  token: string;
  password: string;
};

export type ValidateJWT = {
  token: string;
};

export type RetrieveRedactedEmail = {
  dln: string;
};

const checkEmail = (data: CheckEmail): Promise<unknown> =>
  new Client.Builder().post(`${buildRequestUrl()}/accnt/login/check_email`).data(data).execute();

const sendEmail = (data: SendEmail): Promise<unknown> =>
  new Client.Builder().post(`${buildRequestUrl()}/accnt/reset-password`).data(data).execute();

const resetPassword = (data: ResetPassword): Promise<unknown> =>
  new Client.Builder().post(`${buildRequestUrl()}/accnt/do-reset-password`).data(data).execute();

const retrieveRedactedEmail = (data: RetrieveRedactedEmail): Promise<unknown> =>
  new Client.Builder().post(`${buildRequestUrl()}/accnt/recovery/email`).data(data).execute();

const validateUserAuthorizationToken = (data: ValidateJWT): Promise<unknown> =>
  new Client.Builder().post(`${buildRequestUrl()}/accnt/validate-jwt-token`).data(data).execute();

export const AccountClient = {
  checkEmail,
  sendEmail,
  resetPassword,
  validateUserAuthorizationToken,
  retrieveRedactedEmail,
};
