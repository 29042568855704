import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { push, CallHistoryMethodAction } from 'connected-react-router';
import { AppState } from 'state';
import { sendEmailRequest } from 'state/recovery/actions';
import { invalidateEmail } from 'state/login/actions';
import { LoginActionTypes } from 'state/login/types';
import { RecoveryActionTypes } from 'state/recovery/types';

import PasswordForgot, { FormValues } from './PasswordForgot';

interface StateProps {
  username: string;
  emailValid: boolean | undefined;
  recoveryLoading: boolean;
}

interface DispatchProps {
  submit: (values: FormValues) => void;
  invalidateEmail: () => void;
  redirect: (url: string) => void;
}

export interface ComponentProps extends StateProps, DispatchProps {}

const mapStateToProps = (state: AppState): StateProps => ({
  username: state.login.email.value,
  emailValid: state.login.email.valid,
  recoveryLoading: state.recovery.loading,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  submit: (values: FormValues): RecoveryActionTypes => dispatch(sendEmailRequest(values.username)),
  invalidateEmail: (): LoginActionTypes => dispatch(invalidateEmail()),
  redirect: (url: string): CallHistoryMethodAction => dispatch(push(url)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordForgot);
