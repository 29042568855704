export const buildRequestUrl: () => string = () => {
  const { REACT_APP_HTTP_PROTOCOL, REACT_APP_CSI_DOMAIN, REACT_APP_CSI_PORT } = process.env;
  if (REACT_APP_CSI_PORT) {
    return `${REACT_APP_HTTP_PROTOCOL}://${REACT_APP_CSI_DOMAIN}:${REACT_APP_CSI_PORT}`;
  }
  return `${REACT_APP_HTTP_PROTOCOL}://${REACT_APP_CSI_DOMAIN}`;
};

// For frontend redirects to the quote engine
export const buildQuoteUrl: () => string = () => {
  const { REACT_APP_HTTP_PROTOCOL, REACT_APP_QUOTE_DOMAIN, REACT_APP_QUOTE_PORT } = process.env;
  if (REACT_APP_QUOTE_PORT) {
    return `${REACT_APP_HTTP_PROTOCOL}://${REACT_APP_QUOTE_DOMAIN}:${REACT_APP_QUOTE_PORT}`;
  }
  return `${REACT_APP_HTTP_PROTOCOL}://${REACT_APP_QUOTE_DOMAIN}`;
};
