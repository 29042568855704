import React, { ReactElement } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch } from 'react-router-dom';
import { history } from 'state/store';

import {
  EmailForgot,
  Login,
  PasswordResetSuccess,
  PasswordReset,
  PasswordResetEmailSent,
  PasswordForgot,
  PasswordResetLinkExpired,
  NearlyThere,
  Lost,
} from 'pages';

import { Matrix } from '@rentecarlo/component-library';

export default function router(): ReactElement {
  return (
    <ConnectedRouter history={history}>
      <Switch>
        <Route path='/' exact>
          <Login />
        </Route>
        <Route path='/password/forgot'>
          <PasswordForgot />
        </Route>
        <Route path='/password/reset'>
          <PasswordReset />
        </Route>
        <Route path='/password/success'>
          <PasswordResetSuccess />
        </Route>
        <Route path='/password/check-your-inbox'>
          <PasswordResetEmailSent />
        </Route>
        <Route path='/email/forgot/nearly-there'>
          <NearlyThere />
        </Route>
        <Route path='/email/forgot'>
          <EmailForgot />
        </Route>
        <Route path='/lost'>
          <Lost />
        </Route>
        <Route path='/password/reset-link-expired'>
          <PasswordResetLinkExpired />
        </Route>
        <Route component={Matrix} />
      </Switch>
    </ConnectedRouter>
  );
}
