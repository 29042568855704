import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: proxima-soft;
  font-size: 16px;
  color: #757575;
  line-height: 20px;
  font-weight: bold;
`;

export const Line = styled.div`
  flex: 1;
  height: 1px;
  background-color: #cbcbcb;
`;

export const Text = styled.div`
  margin: 0px 16px;
`;
