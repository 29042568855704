import {
    ConfigActionTypes,
    FEATURE_FLAGS_SUCCESS,
    SET_OPTIMIZELY_ATTRIBUTE,
    TEST_ACTION,
    TEST_SUCCESS_ACTION,
} from './types';

export function testAction(value: string): ConfigActionTypes {
    return {
        type: TEST_ACTION,
        value
    }
}

export function testSuccessAction(): ConfigActionTypes {
    return {
        type: TEST_SUCCESS_ACTION
    }
}

export function fetchFeatureFlags(featureFlags: Object): ConfigActionTypes {
    return {
        type: FEATURE_FLAGS_SUCCESS,
        featureFlags
    }
}

export function setOptimizelyAttribute(key: string, value: string): ConfigActionTypes {
    return {
        type: SET_OPTIMIZELY_ATTRIBUTE,
        key,
        value,
    }
}

export type TestActions =
  | ReturnType<typeof testAction>
  | ReturnType<typeof testSuccessAction>
  | ReturnType<typeof fetchFeatureFlags>
  | ReturnType<typeof setOptimizelyAttribute>;
