import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AppState } from 'state';
import { push, CallHistoryMethodAction } from 'connected-react-router';
import { performRedirect, RedirectActions } from 'state/redirect/actions';
import PasswordResetSuccess from './PasswordResetSuccess';

interface StateProps {
  confirmationCode: string | null;
}

interface DispatchProps {
  redirect: () => void;
  redirectToSignIn: () => void;
}

export interface ComponentProps extends StateProps, DispatchProps {}

const mapStateToProps = (state: AppState): StateProps => ({
  confirmationCode: state.recovery.confirmationCode,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  redirect: (): RedirectActions => dispatch(performRedirect()),
  redirectToSignIn: (): CallHistoryMethodAction => dispatch(push('/')),
});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordResetSuccess);
