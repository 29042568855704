import React, { ReactElement } from 'react';

import { Button } from '@rentecarlo/component-library';

import { TextDivider } from 'components/atoms';

import * as styles from './assets/styles';

interface Props {
  buttonAction: () => void;
}

const NewToVeygo = (props: Props): ReactElement => {
  const { buttonAction } = props;
  return (
    <>
      <TextDivider text='New to Veygo?' />
      <styles.Text>Your account will be created when you buy a policy</styles.Text>
      <styles.ButtonContainer>
        <Button id='getAQuote' buttonType='outline' onClick={buttonAction}>
          Get a quote
        </Button>
      </styles.ButtonContainer>
    </>
  );
};

export default NewToVeygo;
