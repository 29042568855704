import { CallHistoryMethodAction, push } from 'connected-react-router';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { AppState } from 'state';
import { sendEmailRequest } from 'state/recovery/actions';
import { RecoveryActionTypes } from 'state/recovery/types';
import PasswordResetLinkExpired from './PasswordResetLinkExpired';

export interface StateProps {
  username: string | null;
  recoveryLoading: boolean;
  invalidEmail?: boolean;
}

interface DispatchProps {
  sendEmailRequest: (username: string) => void;
  redirectToPasswordForgot: () => void;
  redirectToMatrix: () => void;
}

export interface ComponentProps extends StateProps, DispatchProps {}

const mapStateToProps = (state: AppState): StateProps => ({
  username: state.recovery.username,
  recoveryLoading: state.recovery.loading,
  invalidEmail: state.login.email.valid,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  sendEmailRequest: (username: string): RecoveryActionTypes => dispatch(sendEmailRequest(username)),
  redirectToPasswordForgot: (): CallHistoryMethodAction => dispatch(push('/password/forgot')),
  redirectToMatrix: (): CallHistoryMethodAction => dispatch(push('/matrix')),
});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordResetLinkExpired);
