import {
  LoginState,
  LoginActionTypes,
  CHECK_EMAIL_REQUEST,
  CHECK_EMAIL_FAILURE,
  CHECK_EMAIL_SUCCESS,
  LOGIN_REQUEST,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  INVALIDATE_EMAIL,
  INVALIDATE_PASSWORD,
  RESET_LOGIN,
} from './types';

const initialState: LoginState = {
  email: {
    value: '',
    valid: undefined,
    loading: false,
  },
  password: {
    valid: undefined,
    loading: false,
  },
};

// eslint-disable-next-line @typescript-eslint/default-param-last
function login(state: LoginState = initialState, action: LoginActionTypes): LoginState {
  switch (action.type) {
    case CHECK_EMAIL_REQUEST:
      return {
        ...state,
        email: {
          ...state.email,
          value: action.email,
          loading: true,
        },
      };
    case CHECK_EMAIL_FAILURE:
      return {
        ...state,
        email: {
          ...state.email,
          valid: false,
          loading: false,
        },
      };
    case CHECK_EMAIL_SUCCESS:
      return {
        ...state,
        email: {
          ...state.email,
          valid: true,
          loading: false,
        },
      };
    case LOGIN_REQUEST:
      return {
        ...state,
        password: {
          ...state.password,
          loading: true,
        },
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        password: {
          ...state.password,
          valid: false,
          loading: false,
        },
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        password: {
          ...state.password,
          valid: true,
          loading: false,
        },
      };
    case INVALIDATE_EMAIL:
      return {
        ...state,
        email: {
          ...state.email,
          valid: undefined,
        },
      };
    case INVALIDATE_PASSWORD:
      return {
        ...state,
        password: {
          ...state.password,
          valid: undefined,
        },
      };
    case RESET_LOGIN:
      return initialState;
    default:
      return state;
  }
}

export default login;
