import React, { ReactElement, ReactNode } from 'react';

import { Button } from '@rentecarlo/component-library';

import VeygoLogo from 'assets/logo.svg';

import {
  StyledBottomContent,
  StyledBox,
  StyledBoxContent,
  StyledButtonContainer,
  StyledCardFooterContainer,
  StyledContainer,
  StyledContent,
  StyledTitle,
  StyledSubtitle,
  StyledLogo,
  StyledLogoWrapper,
} from './assets/styles';

export interface Props {
  title: string;
  titleCentered?: boolean;
  buttonAction?: () => void;
  buttonText?: string;
  bottomContent?: ReactNode;
  children: ReactNode;
  subtitle?: ReactNode;
  buttonDisabled?: boolean;
  buttonIsLoading?: boolean;
  cardFooterContent?: ReactNode;
}

const Page = (props: Props): ReactElement => {
  const {
    cardFooterContent,
    children,
    title,
    titleCentered = false,
    subtitle,
    buttonAction,
    buttonText,
    bottomContent,
    buttonDisabled,
    buttonIsLoading,
  } = props;
  return (
    <StyledContainer>
      <StyledContent>
        <StyledLogoWrapper>
          <StyledLogo src={VeygoLogo} />
        </StyledLogoWrapper>
        <StyledBox>
          <StyledTitle centered={titleCentered}>{title}</StyledTitle>
          <StyledBoxContent>{children}</StyledBoxContent>
          {buttonText && (
            <StyledButtonContainer>
              <Button
                id='page-continueButton'
                onClick={buttonAction}
                disabled={buttonDisabled}
                isLoading={buttonIsLoading}
              >
                {buttonText}
              </Button>
            </StyledButtonContainer>
          )}
          {subtitle && <StyledSubtitle>{subtitle}</StyledSubtitle>}
          {cardFooterContent && (
            <StyledCardFooterContainer>{cardFooterContent}</StyledCardFooterContainer>
          )}
        </StyledBox>
        {bottomContent && <StyledBottomContent>{bottomContent}</StyledBottomContent>}
      </StyledContent>
    </StyledContainer>
  );
};

export default Page;
