import { fork } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { saga as configSaga } from './config';
import { saga as loginSaga } from './login';
import { saga as resetSaga } from './recovery';
import { saga as redirectSaga } from './redirect';
import { runInit } from './init';

function* rootSaga(): SagaIterator {
  yield fork(runInit);
  yield fork(configSaga);
  yield fork(loginSaga);
  yield fork(resetSaga);
  yield fork(redirectSaga);
}

export default rootSaga;
