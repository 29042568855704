/* eslint-disable @typescript-eslint/explicit-function-return-type */
import Styled from 'styled-components';

export const StyledSubtitleContainer = Styled.div`
  line-height: 20px;
`;

export const StyledSubtitle = Styled.span`
  font-size: 16px;
  color: ${({ theme }) => theme.textSecondary};
  font-family: proxima-soft;
`;

export const StyledPanelContainer = Styled.div`
  margin-top: 24px;
  margin-bottom: 40px;
`;

export const StyledPanelWrapper = Styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledRedactedEmailWrapper = Styled.div`
  color: #364344;
  font-family: Graphie;
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
`;
