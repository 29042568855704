import { SET_REDIRECT_URL, PERFORM_REDIRECT, RedirectActionTypes } from './types';

export function setRedirectUrl(url: string | undefined): RedirectActionTypes {
  return {
    type: SET_REDIRECT_URL,
    url,
  };
}

export function performRedirect(): RedirectActionTypes {
  return {
    type: PERFORM_REDIRECT,
  };
}

export type RedirectActions =
  | ReturnType<typeof setRedirectUrl>
  | ReturnType<typeof performRedirect>;
