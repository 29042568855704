import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AppState } from 'state';
import { RecoveryActionTypes } from 'state/recovery/types';
import { getConfirmationCodeFromUrl, getTokenFromUrl, getUsernameFromUrl } from 'utils/parseUrl';
import {
  setToken,
  resetPasswordRequest,
  setConfirmationCode,
  setUsername,
} from 'state/recovery/actions';
import PasswordReset from './PasswordReset';

interface StateProps {
  recoveryLoading: boolean;
}
interface DispatchProps {
  setToken: (url: string) => void;
  setConfirmationCode: (url: string) => void;
  setUsername: (url: string) => void;
  resetPassword: (password: string) => void;
}

export interface ComponentProps extends StateProps, DispatchProps {}

const mapStateToProps = (state: AppState): StateProps => ({
  recoveryLoading: state.recovery.loading,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  setToken: (url: string): RecoveryActionTypes => dispatch(setToken(getTokenFromUrl(url))),
  setConfirmationCode: (url: string): RecoveryActionTypes =>
    dispatch(setConfirmationCode(getConfirmationCodeFromUrl(url))),
  setUsername: (url: string): RecoveryActionTypes => dispatch(setUsername(getUsernameFromUrl(url))),
  resetPassword: (password: string): RecoveryActionTypes =>
    dispatch(resetPasswordRequest(password)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);
